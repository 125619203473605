import { writable } from "svelte/store";
import type { APIResponse } from "tupperschema";
import { createGroupStore } from "./group";
import { createTupperStore } from "./tupper";
import { createGroupIdTupperMap, createSortedGroupsStore } from "./derived";
import { canonGroups, canonTuppers, canonUser } from "..";

export const listPage = createListPageStore();
export const selected = createSelectionStore(listPage);
export const tupMap = createTupperStore(selected);
export const groupMap = createGroupStore(selected);
export const workingUser = writable<APIResponse<"/api/user", "GET"> | null>(null);

canonTuppers.subscribe((tuppers) => tupMap.setFromArray(tuppers));
canonGroups.subscribe((groups) => groupMap.setFromArray(groups));
canonUser.subscribe((user) => workingUser.set(user));

// derived stores
export const groupIdTupperMap = createGroupIdTupperMap(groupMap, tupMap);
export const sortedGroups = createSortedGroupsStore(groupMap);

export type SelectionStore = ReturnType<typeof createSelectionStore>;
export type ListPageStore = ReturnType<typeof createListPageStore>;
type PageData = {
  scrollTo: string | null;
  checkMode: boolean;
  dirty: boolean;
}

function createListPageStore() {
  const initStore: PageData = {
    scrollTo: null,
    checkMode: false,
    dirty: false
  }
  const { subscribe, update, set } = writable(initStore);

  return {
    subscribe,
    set,
    update,
  }
}

function createSelectionStore(listPageStore: ListPageStore) {
  const { subscribe, update, set } = writable({
    tuppers: new Set() as Set<number>,
    groups: new Set() as Set<number>,
  })
  let deselectCallback = () => { };
  return {
    subscribe,
    set,
    selectTuppers,
    selectGroups,
    deselectTuppers,
    deselectGroups,
    deselectAll,
    setDeselectCallback,
  };

  function selectTuppers(tupperIds: number[], pageOptions?: Partial<PageData>) {
    update((selection) => {
      if (selection.groups.size > 0) {
        selection.groups.clear();
        deselectCallback();
      }
      for (let id of tupperIds) selection.tuppers.add(id);
      return selection;
    });
    if (pageOptions) updatePage(pageOptions);
  }

  function selectGroups(groupIds: number[], pageOptions?: Partial<PageData>) {
    update((selection) => {
      if (selection.tuppers.size > 0) {
        selection.tuppers.clear();
        deselectCallback();
      }
      for (let id of groupIds) selection.groups.add(id);
      return selection;
    });
    if (pageOptions) updatePage(pageOptions);
  }

  function deselectTuppers(tupperIds: number[], pageOptions?: Partial<PageData>) {
    update((selection) => {
      if (selection.groups.size > 0) {
        selection.groups.clear();
        deselectCallback();
      }

      for (let id of tupperIds) selection.tuppers.delete(id);
      return selection;
    });
    if (pageOptions) updatePage(pageOptions);
  }

  function deselectGroups(groupIds: number[], pageOptions?: Partial<PageData>) {
    update((selection) => {
      if (selection.tuppers.size > 0) {
        selection.tuppers.clear();
        deselectCallback();
      }

      for (let id of groupIds) selection.groups.delete(id);
      return selection;
    });
    if (pageOptions) updatePage(pageOptions);
  }

  function deselectAll() {
    deselectCallback();
    update((selection) => {
      selection.tuppers.clear();
      selection.groups.clear();
      return selection;
    });
  }

  function setDeselectCallback(callback: () => void) {
    deselectCallback = callback;
  }

  function updatePage(pageOptions: Partial<PageData>) {
    listPageStore.update((store) => ({
      checkMode: pageOptions.checkMode ?? store.checkMode,
      scrollTo: pageOptions.scrollTo ?? store.scrollTo,
      dirty: pageOptions.dirty ?? store.dirty
    }));
  }
}