import { writable } from "svelte/store";
import type { Tupper } from "tupperschema";
import type { SelectionStore } from ".";

export function createTupperStore(selected: SelectionStore) {
  const { subscribe, set, update } = writable(new Map as Map<number, Tupper>);
  return {
    subscribe,
    set,
    setFromArray,
    addTuppers,
    updateTupper,
    removeTuppers,
  };

  function setFromArray(tuppers: Tupper[]) {
    set(mapFromArray(tuppers));
  }

  function addTuppers(tuppers: Tupper[]) {
    selected.deselectAll();
    update((tupMap) => {
      tuppers.forEach(t => tupMap.set(t.id, t));
      return tupMap;
    });
    selected.selectTuppers(tuppers.map(t => t.id));
  }

  function updateTupper(tupper: Tupper) {
    selected.deselectAll();
    update((tuppers) => {
      const oldTupper = tuppers.get(tupper.id);
      if (!oldTupper) return tuppers;
      Object.assign(oldTupper, tupper);
      return tuppers;
    });
    selected.selectTuppers([tupper.id]);
  }

  function removeTuppers(ids: number[]) {
    selected.deselectAll();
    if (!ids.length) return;
    update((tuppers) => {
      ids.forEach((id) => tuppers.delete(id));
      return tuppers;
    });
  }

  function mapFromArray(arr: Tupper[]) {
    return new Map(arr.map((tupper) => [tupper.id, tupper] as [number, Tupper]));
  }
}