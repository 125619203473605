import { writable } from "svelte/store";
import type { Group } from "tupperschema";
import type { SelectionStore } from ".";

export function createGroupStore(selected: SelectionStore) {
  const { subscribe, set, update } = writable(new Map() as Map<number, Group>);

  return {
    subscribe,
    set,
    setFromArray,
    addGroup,
    updateGroup,
    removeGroup,
  };

  function setFromArray(groups: Group[]) {
    set(mapFromArray(groups));
  }

  function addGroup(group: Group) {
    selected.deselectAll();
    update((groupMap) => {
      groupMap.set(group.id, group);
      return groupMap;
    });
    selected.selectGroups([group.id]);
  }

  function updateGroup(group: Group) {
    selected.deselectAll();
    update((groups) => {
      const oldGroup = groups.get(group.id);
      if (!oldGroup) return groups;
      Object.assign(oldGroup, group);
      return groups;
    });
    selected.selectGroups([group.id]);
  }

  function removeGroup(id: number) {
    selected.deselectAll();
    update((groups) => {
      groups.delete(id);
      return groups;
    });
    selected.selectGroups([], {
      dirty: false
    });
  }

  function mapFromArray(arr: Group[]) {
    return new Map(arr.map((group) => [group.id, group] as [number, Group]));
  }
}