import { derived } from "svelte/store";
import type { Tupper } from "tupperschema";
import type { createGroupStore } from "./group";
import type { createTupperStore } from "./tupper";

export function createGroupIdTupperMap(
  groupMap: ReturnType<typeof createGroupStore>,
  tupperMap: ReturnType<typeof createTupperStore>
) {
  return derived([groupMap, tupperMap], ([$groupMap, $tupperMap]) => {
    const groupIdTupperMap = new Map<number | null, Tupper[]>();
    for (const tupper of $tupperMap.values()) {
      const groupId = tupper.group_id;
      let entry = groupIdTupperMap.get(groupId);
      if (!entry) {
        entry = [];
        groupIdTupperMap.set(groupId, entry);
      }
      entry.push(tupper);
    }
    for (const group of $groupMap.values()) {
      const tuppers = groupIdTupperMap.get(group.id);
      if (!tuppers) groupIdTupperMap.set(group.id, []);
      else tuppers.sort(sortTuppers);
    }
    groupIdTupperMap.get(null)?.sort(sortTuppers);
    return groupIdTupperMap;

    function sortTuppers(a, b) {
      if (a.group_id === b.group_id) {
        return a.group_id !== null
          ? (a.group_pos ?? -1) - (b.group_pos ?? -1)
          : a.position - b.position;
      }
      return getSortPosition(a) - getSortPosition(b);
    }
    function getSortPosition(tupper: Tupper) {
      if (tupper.group_id === null) return tupper.position;
      return ($groupMap.get(tupper.group_id) || tupper).position;
    }
  });
}

export function createSortedGroupsStore(groupMap: ReturnType<typeof createGroupStore>) {
  return derived(groupMap, ($groupMap) =>
    [...$groupMap.values()]
      .sort((a, b) => a.position - b.position)
  );
}

